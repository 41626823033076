import React, { Key } from 'react';

import { Col, Modal, notification, Row, Table } from 'antd';
import { registerRenderer, textRenderer } from 'handsontable/renderers';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import AddReferenceFormModal from '@components/PriceTableForm/components/AddReferenceFormModal';
import { PriceTableReferenceData } from '@components/PriceTableForm/models/price-table-form-models';
import { SharedData } from '@components/ProductForm/models/product-form-models';
import { HotTable } from '@handsontable/react';
import { Reference } from '@models/Reference';
import { Container } from '@pages/MainPage/styles';
import { CustomButton, FullScreenModal } from '@styles/globals';

registerRenderer('customStylesRenderer', (hotInstance, TD, ...rest) => {
  textRenderer(hotInstance, TD, ...rest);
  TD.style.fontSize = '18px';
});

interface PriceTableStepProps {
  sharedData?: SharedData;
  changeSharedData?: Function;
  formValues: {
    references: Reference[];
    referencesTableData: PriceTableReferenceData[];
    prices: number[][];
    refs: any[];
  };
  setFormValues: Function;
  goToNextStep: Function;
  goToBackStep: Function;
  isEditing?: boolean;
  render: boolean;
}

const PriceTableStep: React.FC<PriceTableStepProps> = ({
  formValues,
  setFormValues,
  goToNextStep,
  goToBackStep,
  isEditing = false,
  render = true,
}) => {
  const hotRefs = React.useRef<Array<any>>([]);
  const addHotRef = () => hotRefs.current.push(React.createRef());
  const removeHotRef = (indexToRemove: number) => hotRefs.current.splice(indexToRemove, 1);

  const [addReferenceModalIsVisible, setAddReferenceModalIsVisible] = React.useState(false);

  const [selectedReferences, setSelectedReferences] = React.useState<Reference[]>([]);
  const [selectedReferencesData, setSelectedReferencesData] = React.useState<PriceTableReferenceData[]>([]);
  const [expandedRowKeys, setExpandedRowKeys] = React.useState<Key[]>([]);

  const onCloseAddReferenceModal = () => setAddReferenceModalIsVisible(false);

  const onShowAddReferenceModal = () => {
    setAddReferenceModalIsVisible(true);
  };

  React.useEffect(() => {
    if (!isEditing && formValues?.references) {
      setSelectedReferences(formValues?.references);
      setSelectedReferencesData(formValues?.referencesTableData);
      setExpandedRowKeys(formValues.references?.map((reference) => reference?.ReferenceCode) as Key[]);
      formValues?.references.forEach(() => {
        addHotRef();
      });
    }
  }, []);

  React.useEffect(() => {
    if (isEditing && formValues?.references) {
      setSelectedReferences(formValues?.references);
      setSelectedReferencesData(formValues?.referencesTableData);
      setExpandedRowKeys(formValues.references?.map((reference) => reference?.ReferenceCode) as Key[]);
      formValues?.references.forEach(() => {
        addHotRef();
      });
    }
  }, [formValues]);

  const finishStep = async () => {
    const values = getFormValues();

    setFormValues(values);

    if (selectedReferences?.length > 0) {
      showModalConfirmation();
    } else {
      notification.warning({
        message: 'Aviso',
        description: `Adicione pelo menos uma referência na tabela de preço.`,
      });
    }
  };

  const returnStep = async () => {
    const values = getFormValues();
    setFormValues(values);
    goToBackStep();
  };

  const getFormValues = () => {
    const prices = getPrices() !== null ? { prices: getPrices() } : {};
    const values = {
      references: selectedReferences,
      referencesTableData: selectedReferencesData,
      refs: hotRefs?.current,
      ...prices,
    };
    return values;
  };

  const getPrices = () => {
    if (hotRefs?.current) {
      const hots = hotRefs?.current?.map((refs: any) => refs?.current?.hotInstance);
      const tablesValues = hots?.map((hot) => hot?.getData());
      return tablesValues;
    }

    return null;
  };

  const showModalConfirmation = () => {
    Modal.confirm({
      title: `Tem certeza que deseja ${isEditing ? 'editar' : 'cadastrar'}?`,
      icon: <ExclamationCircleOutlined />,
      okText: isEditing ? 'Editar' : 'Cadastrar',
      cancelText: 'Cancelar',
      onOk() {
        const values = getFormValues();
        goToNextStep(values);
      },
    });
  };

  const removeReference = (key: string) => {
    const newList = selectedReferences.filter((reference) => reference?.ReferenceCode !== key);
    const refIndexToRemove = selectedReferences.findIndex((reference) => reference?.ReferenceCode === key);
    setSelectedReferences(newList);
    removeReferenceDataTableAtIndex(refIndexToRemove);
    removeHotRef(refIndexToRemove);
  };

  const addReferences = (newReferences: Reference[]) => {
    setSelectedReferences((prev) => [...prev, ...newReferences] as any);
    generateReferenceDataTable(newReferences);
  };

  const generateReferenceDataTable = (references: Reference[]) => {
    const referencesData: PriceTableReferenceData[] = [];
    const keys: Key[] = [];
    references.forEach((reference) => {
      const tableColumn = reference?.colors?.map((color) => color?.name) || [];
      const tableRow = reference?.colors[0]?.products?.map((product) => product?.size) || [];
      const tableCellsData: number[][] = [];

      for (let tr = 0; tr < tableRow?.length; tr++) {
        let row: number[] = [];
        for (let tc = 0; tc < tableColumn?.length; tc++) {
          row = [...row, 0];
        }
        tableCellsData.push(row);
      }

      referencesData.push({
        colHeaders: tableColumn,
        rowHeaders: tableRow,
        cellsData: tableCellsData,
      });

      keys.push(reference?.ReferenceCode as Key);
      addHotRef();
    });

    setExpandedRowKeys(keys);
    setSelectedReferencesData((prev) => [...prev, ...referencesData] as any);
  };

  const removeReferenceDataTableAtIndex = (indexToRemove: number) => {
    const newList: PriceTableReferenceData[] = [...selectedReferencesData];
    newList?.splice(indexToRemove, 1);
    setSelectedReferencesData(newList);
  };

  const toggleExpand = (key: any) => {
    const include = expandedRowKeys.includes(key);
    if (key) {
      if (include) {
        const updatedKeys = [...expandedRowKeys];
        setExpandedRowKeys(updatedKeys.filter((k) => k !== key));
      } else {
        setExpandedRowKeys([...expandedRowKeys, key]);
      }
    }
  };

  const formatTR = {
    pattern: 'R$ 0.0,00',
    culture: 'pt-BR',
  };

  return (
    <>
      {render && (
        <>
          <div className="d-flex" style={{ width: '100%', flexDirection: 'column', alignItems: 'center' }}>
            <Row className="d-flex" style={{ width: '100%', justifyContent: 'flex-start', alignItems: 'center' }}>
              <Col span={8}>
                <CustomButton type="primary" style={{ marginBottom: 10 }} onClick={onShowAddReferenceModal}>
                  Adicionar referência
                </CustomButton>
              </Col>
            </Row>

            <Row className="d-flex" style={{ width: '100%', justifyContent: 'flex-start', alignItems: 'center' }}>
              <Col span={24}>
                <h2 style={{ margin: '20px 0 30px 0' }}>Referências adicionadas</h2>
              </Col>
            </Row>

            <Row className="d-flex" style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
              <Table
                rowKey={(reference) => reference?.ReferenceCode}
                bordered={true}
                showHeader={false}
                pagination={false}
                style={{ width: '100%' }}
                className="table-antd"
                dataSource={selectedReferences}
                columns={[
                  {
                    dataIndex: 'reference',
                    render: (row: string, reference: Reference, index) => (
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>{reference?.ReferenceCode + ' - ' + reference.name}</span>
                        {!isEditing && (
                          <p
                            style={{
                              color: 'red',
                              textDecoration: 'underline',
                              margin: 0,
                              padding: 0,
                              cursor: 'pointer',
                            }}
                            onClick={() => removeReference(reference?.ReferenceCode)}
                          >
                            Excluir
                          </p>
                        )}
                      </div>
                    ),
                  },
                ]}
                expandable={{
                  expandRowByClick: true,
                  onExpand: (key, reference) => toggleExpand(reference?.ReferenceCode),
                  expandedRowKeys: expandedRowKeys,
                  expandedRowRender: (reference, index) => (
                    <Row
                      key={reference?.ReferenceCode}
                      style={{
                        width: '100%',
                        height: 'auto',
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        overflow: 'auto',
                      }}
                    >
                      {selectedReferencesData.length > 0 && (
                        <>
                          <Col span={3}>
                            <div
                              style={{
                                width: '120px',
                                height: '50px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '18px',
                                fontWeight: 'bold',
                              }}
                            ></div>
                            {selectedReferencesData[index]?.rowHeaders?.map((row) => (
                              <div
                                style={{
                                  width: '120px',
                                  height: '50px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  fontSize: '18px',
                                  fontWeight: 'bold',
                                }}
                              >
                                <span>{row}</span>
                              </div>
                            ))}
                          </Col>

                          <Col span={21} style={{ height: '100%' }}>
                            <Row style={{ display: 'flex', flexWrap: 'nowrap' }}>
                              {selectedReferencesData[index]?.colHeaders?.map((col) => (
                                <div
                                  style={{
                                    width: '120px',
                                    minWidth: '120px',
                                    height: '50px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                  }}
                                >
                                  <span>{col}</span>
                                </div>
                              ))}
                            </Row>

                            <HotTable
                              ref={hotRefs?.current[index]}
                              renderer="customStylesRenderer"
                              currentRowClassName="customStyleHeader"
                              type={'numeric'}
                              data={selectedReferencesData[index]?.cellsData}
                              colHeaders={false}
                              rowHeaders={false}
                              className="htCenter htMiddle"
                              height={selectedReferencesData[index]?.rowHeaders.length * 50 + 50}
                              width={selectedReferencesData[index]?.colHeaders.length * 120 + 50}
                              colWidths={120}
                              rowHeights={50}
                              licenseKey="non-commercial-and-evaluation"
                            />
                          </Col>
                        </>
                      )}
                    </Row>
                  ),
                }}
              />
            </Row>

            <CustomButton type="primary" style={{ marginTop: 40, width: '100%' }} onClick={finishStep}>
              {isEditing ? 'Editar' : 'Cadastrar'}
            </CustomButton>

            <CustomButton type="default" style={{ marginTop: 20, width: '100%' }} onClick={returnStep}>
              Voltar
            </CustomButton>
          </div>

          <FullScreenModal
            destroyOnClose={true}
            visible={addReferenceModalIsVisible}
            onOk={onCloseAddReferenceModal}
            onCancel={onCloseAddReferenceModal}
            footer={null}
            forceRender={false}
          >
            <Row align="middle" justify="center">
              <Col span={24} className="d-flex" style={{ width: '1000px' }}>
                <div className="steps-action d-flex" style={{ width: '100%' }}>
                  <Container
                    style={{
                      background: '#fff',
                      padding: '100px',
                      paddingTop: '110px',
                      borderRadius: '8px',
                      width: '100%',
                      minHeight: '850px',
                    }}
                  >
                    <AddReferenceFormModal
                      onCloseModal={onCloseAddReferenceModal}
                      setReferences={addReferences}
                      selected={selectedReferences}
                    />
                  </Container>
                </div>
              </Col>
            </Row>
          </FullScreenModal>
        </>
      )}
    </>
  );
};

export default PriceTableStep;
