import {
  useCallback,
  useState,
} from 'react';

import {
  Col,
  Modal,
  Row,
  Typography,
  Upload,
} from 'antd';
import { parse } from 'papaparse';

import {
  CheckOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  UploadOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import HeaderButton from '@components/HeaderButton';
import { useUploadCustomers } from '@hooks/UploadCustomersContext';
import { Customer } from '@models/Customer';
import { FullScreenModal } from '@styles/globals';

import SelectRepresentativeForm from '../ChangeRepresentativeForm';

const { Text } = Typography;
const { Title } = Typography;

const UploadCustomersHeader: React.FC = () => {
  const { customers, setCustomers, representative, setRepresentative, registerCustomers, registering } =
    useUploadCustomers();

  const [representativeModalIsVisible, setRepresentativeModalIsVisible] = useState<boolean>(false);
  const handleShowRepresentativeModal = useCallback(() => {
    setRepresentativeModalIsVisible(true);
  }, [setRepresentativeModalIsVisible]);

  const handleCloseRepresentativeModal = useCallback(() => {
    setRepresentativeModalIsVisible(false);
  }, [setRepresentativeModalIsVisible]);

  const handleClean = useCallback(() => {
    Modal.confirm({
      title: 'Tem certeza que deseja limpar?',
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <Text>Os dados da tabela importada e o representante selecionado serão perdidos</Text>
        </>
      ),
      okText: 'Limpar dados',
      type: 'warn',
      okType: 'danger',
      onOk() {
        setRepresentative(null);
        setCustomers([]);
      },
    });
  }, [setRepresentative, setCustomers]);

  const convertRowToCustomer = (row: any) => {
    const customer: Customer = {
      code: row.legalperson_code,
      integrationId: 29,
      cnpj: row.CNPJ,
      isInactive: false,
      branchInsertCode: 1,
      name: row.RAZAO_SOCIAL?.replace("'", ''),
      fantasyName: row.NOME?.replace("'", ''),
      uf: row.ESTADO,
      numberStateRegistration: row.INSCRICAO_ESTADUAL,
      homePage: '',
      typeSubTributary: '-',
      typeDescriptionSuFrama: '-',
      numberEmployees: 0,
      monthlyInvoicing: 0,
      shareCapital: 0,
      codeActivityCnae: 0,
      codeActivityCnae2: 0,
      typeTaxRegime: 0,
      addresses: [
        {
          integrationId: 1,
          addressType: 5,
          publicPlace: 'AV',
          address: row.ENDERECO?.replace("'", ''),
          addressNumber: row.N?.replace(/\D/g, '').length > 0 ? parseInt(row.NUMERO.replace(/\D/g, ''), 10) : 0,
          complement: row.N?.replace(/\d/g, ''),
          neighborhood: row.BAIRRO,
          cityName: row.CIDADE?.replace("'", ''),
          stateAbbreviation: row.ESTADO,
          ibgeCityCode: 0,
          bcbCountryCode: 0,
          postOfficeBox: '0',
          cep: row.CEP?.replace('.', '').replace('/', '').replace('-', ''),
          countryName: 'Brasil',
          reference: '',
        },
      ],
      phones: [
        {
          integrationId: 29,
          typeCode: 93,
          number: row.TELEFONE,
          isDefault: true,
          typeName: null,
          branchLine: null,
        },
      ],
      emails: [
        {
          legalpersonCode: row.legalperson_code,
          typeCode: 91,
          typeName: null,
          email: row.E_MAIL,
          isDefault: true,
        },
      ],
    };

    return customer;
  };

  const emptyFunction = useCallback(() => {
    return;
  }, []);

  const beforeUpload = useCallback(
    async (file: File) => {
      if (file.type !== 'text/csv') {
        console.error('Arquivo inválido');
        return;
      }

      const reader = new FileReader();
      reader.onload = (event) => {
        if (!event.target?.result) return;

        parse(event.target.result as string, {
          header: true,
          skipEmptyLines: true,
          complete: (result) => {
            const jsonData = result.data.map(convertRowToCustomer);
            setCustomers(jsonData);
          },
        });
      };
      reader.readAsText(file);
    },
    [setCustomers],
  );

  const getHeaderButtons = () => {
    if (!customers || customers.length === 0)
      return (
        <Upload accept=".csv" showUploadList={false} beforeUpload={beforeUpload} customRequest={emptyFunction}>
          <HeaderButton
            text="Carregar arquivo .csv"
            onClick={emptyFunction}
            Icon={<UploadOutlined style={{ fontSize: '20px', color: 'var(--primary-color)' }} />}
          />
        </Upload>
      );

    return (
      <Col>
        <Row>
          <HeaderButton
            text={
              representative
                ? `Alterar o representante # ${representative?.code} - ${representative?.name}`
                : 'Selecionar o representante'
            }
            onClick={handleShowRepresentativeModal}
            Icon={<UserAddOutlined style={{ fontSize: '20px', color: 'var(--primary-color)' }} />}
          />
        </Row>

        <Row justify="end" style={{ justifyContent: 'end' }}>
          <Col>
            {representative && !registering && (
              <HeaderButton
                text="Cadastrar lojistas"
                onClick={() => {
                  Modal.confirm({
                    title: 'Deseja iniciar o cadastro dos lojistas?',
                    icon: <ExclamationCircleOutlined />,
                    content: (
                      <>
                        <Text>
                          Após iniciar o cadastro, não atualize ou saia da tela enquanto o status de todos os lojistas
                          não estiver como <Text strong>Enviado</Text>
                        </Text>
                        ;
                      </>
                    ),
                    okText: 'Iniciar cadastro',

                    onOk() {
                      registerCustomers(customers, representative);
                    },
                  });
                }}
                Icon={<CheckOutlined style={{ fontSize: '20px', color: 'var(--primary-color)' }} />}
              />
            )}
          </Col>
          {customers && (
            <HeaderButton
              text="Limpar"
              onClick={handleClean}
              Icon={<DeleteOutlined style={{ fontSize: '20px', color: '#ff4d4f' }} />}
            />
          )}
        </Row>
      </Col>
    );
  };

  return (
    <>
      <FullScreenModal
        destroyOnClose={true}
        open={representativeModalIsVisible}
        onOk={() => {}}
        onCancel={handleCloseRepresentativeModal}
        footer={null}
        forceRender={false}
        maskClosable={false}
        bodyStyle={{ padding: 0, margin: 0, width: '100vw', maxWidth: '100vw' }}
      >
        <div style={{ maxHeight: '92vh', overflowY: 'auto', margin: '0' }}>
          <Row>
            <Col
              span={24}
              className="d-flex"
              style={{ minWidth: '1000px', height: '100%', paddingLeft: 50, paddingRight: 50 }}
            >
              <SelectRepresentativeForm
                currentRepresentative={representative}
                handleCloseRepresentativeModal={handleCloseRepresentativeModal}
                handleSetRepresentative={setRepresentative}
              ></SelectRepresentativeForm>
            </Col>
          </Row>
        </div>
      </FullScreenModal>
      <Row className="mt-8" justify="space-between">
        <Col>
          <Title level={2}>Importar lojistas</Title>
        </Col>
        <Col>{getHeaderButtons()}</Col>
      </Row>
    </>
  );
};

export default UploadCustomersHeader;
