import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react';

import { message } from 'antd';

import { Customer } from '@models/Customer';
import * as CustomerService from '@services/customer';
import { CUSTOMER_PAGINATION_SIZE, INITIAL_PAGE } from '@utils/constants';

interface CustomerState {
  customers: any[];
  customerStatus: 'APPROVED' | 'PENDING';
  customerFilters: any;
  currentCustomer: Customer;
  customersTotal: number;
  customersPage: number;
  loadingCustomers: boolean;
  loadingCurrentCustomer: boolean;
  loadCustomersPaginated: Function;
  loadCustomerByCode: Function;
  changeCustomerStatus: Function;
  acceptCustomerInBrand: Function;
}

interface CustomerProviderProps {
  children: ReactNode;
}

export const CustomerContext = createContext<CustomerState>({} as CustomerState);
const CustomerProvider: React.FC<CustomerProviderProps> = ({ children }) => {
  const [customers, setCustomers] = useState<any[]>([]);
  const [customerStatus, setCustomerStatus] = useState<'APPROVED' | 'PENDING'>('APPROVED');
  const [customerFilters, setCustomerFilters] = useState<any>({} as any);
  const [currentCustomer, setCurrentCustomer] = useState<Customer>({} as Customer);
  const [loadingCustomers, setLoading] = useState<boolean>(false);
  const [loadingCurrentCustomer, setLoadingCurrentCustomer] = useState<boolean>(false);
  const [customersTotal, setTotal] = useState<number>(0);
  const [customersPage, setPage] = useState<number>(INITIAL_PAGE);

  const loadCustomersPaginated = async (
    page: number,
    status: 'APPROVED' | 'PENDING',
    filters?: { searchByName: string },
    pageSize?: number,
  ) => {
    setLoading(true);
    setPage(page);
    setCustomerStatus(status);

    if (filters) setCustomerFilters(filters);
    else setCustomerFilters({});

    try {
      const options = {
        page,
        pageSize: pageSize ?? CUSTOMER_PAGINATION_SIZE,
        status: status ?? 'APPROVED',
        orderBy: 'name',
        direction: 'ASC',
        ...(filters && { ...filters }),
      };
      const { data: _customers } = await CustomerService.getCustomers(options);
      console.log(_customers);
      if (_customers.total === 0) message.warning('Nenhum registro encontrado!');
      setCustomers(_customers.data);
      setTotal(_customers.total);
    } catch {
      message.error('Erro ao carregar lojistas!');
    } finally {
      setLoading(false);
    }
  };

  const loadCustomerByCode = useCallback(
    async (customerCode: number) => {
      setLoadingCurrentCustomer(true);

      try {
        const { data: _customer } = await CustomerService.getCustomerByCode(customerCode);

        setCurrentCustomer(_customer);
      } catch {
        message.error('Erro ao carregar perfil de lojista!');
      } finally {
        setLoadingCurrentCustomer(false);
      }
    },
    [customers, setCurrentCustomer, setLoadingCurrentCustomer],
  );

  const changeCustomerStatus = useCallback(async (customerCode: number, status: string) => {
    try {
      const success = await CustomerService.changeCustomerStatus(customerCode, status);
    } catch (error) {
      message.error(`Erro ao mudar status do lojista. Erro: ${error}`);
    }
  }, []);

  const acceptCustomerInBrand = useCallback(async (brands: any[], legalPersonCode: number) => {
    try {
      setLoading(true);
      return await CustomerService.acceptCustomerInBrand(brands, legalPersonCode);
    } catch (err) {
      message.error(`Erro ao aceitar lojista. Erro: ${err}`);
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <CustomerContext.Provider
      value={{
        customers,
        currentCustomer,
        customerStatus,
        loadingCustomers,
        loadingCurrentCustomer,
        customersPage,
        customersTotal,
        customerFilters,
        loadCustomersPaginated,
        loadCustomerByCode,
        changeCustomerStatus,
        acceptCustomerInBrand,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};

const useCustomer = () => {
  return useContext(CustomerContext);
};

export { CustomerProvider, useCustomer };
