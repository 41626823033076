import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react';

import { message } from 'antd';

import { Customer } from '@models/Customer';
import { Representative } from '@models/Representative';
import { createCustomer } from '@services/customer';

interface UploadCustomersState {
  loading: boolean;
  customers: Customer[];
  representative: Representative | null;
  registering: boolean;
  setCustomers: Function;
  setRepresentative: Function;
  registerCustomers: Function;
}
interface CustomersProviderProps {
  children: ReactNode;
}

export const UploadCustomersContext = createContext<UploadCustomersState>({} as UploadCustomersState);
const UploadCustomersProvider: React.FC<CustomersProviderProps> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [registering, setRegistering] = useState<boolean>(false);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [representative, setRepresentative] = useState<Representative | null>(null);

  const registerCustomers = useCallback(
    async (customersRegister, representativeRegister) => {
      console.log(customersRegister);

      if (!registering) {
        setRegistering(true);

        try {
          let updatedCustomers = [...customersRegister];

          for (let index = 0; index < updatedCustomers.length; index++) {
            try {
              const response = await createCustomer({
                ...updatedCustomers[index],
                representativeCode: representativeRegister?.code,
              });

              updatedCustomers = updatedCustomers.map((customer, i) =>
                i === index ? { ...customer, code: response.data.code } : customer,
              );
            } catch (err) {
              message.error(`Erro cadastrar o lojista ${updatedCustomers[index].name}`);
              console.log(err);
              updatedCustomers = updatedCustomers.map((customer, i) =>
                i === index ? { ...customer, code: -1 } : customer,
              );
            }

            setCustomers([...updatedCustomers]);
          }
        } catch {
          message.error('Erro ao cadastrar lojistas');
        } finally {
          setRegistering(false);
        }
      }
    },
    [registering, setRegistering, setCustomers],
  );

  return (
    <UploadCustomersContext.Provider
      value={{
        loading,
        customers,
        representative,
        registering,
        setCustomers,
        setRepresentative,
        registerCustomers,
      }}
    >
      {children}
    </UploadCustomersContext.Provider>
  );
};

const useUploadCustomers = () => {
  return useContext(UploadCustomersContext);
};

export { UploadCustomersProvider, useUploadCustomers };
