import './styles.css';

import { useCallback, useState } from 'react';

import { Button, Col, Row, Typography } from 'antd';

import { UserSwitchOutlined } from '@ant-design/icons';
import LoadingComponents from '@components/LoadingComponents';
import { useIntegration } from '@hooks/IntegrationContext';
import { useOrder } from '@hooks/OrdersContext';
import { Order } from '@models/Order';
import { FullScreenModal } from '@styles/globals';
import { formatCnpj, formatCpf } from '@utils/index';

import ChangeCustomerForm from './ChangeCustomerForm';
import ChangeRepresentativeForm from './ChangeRepresentativeForm';

const { Text } = Typography;

interface DetailProps {
  order: Order;
  option: string;
}

const Detail: React.FC<DetailProps> = ({ order, option }) => {
  const { hasMerpIntegration } = useIntegration();
  const { loadingOrderDetail } = useOrder();
  const [representativeModalIsVisible, setRepresentativeModalIsVisible] = useState<boolean>(false);
  const handleShowRepresentativeModal = useCallback(() => {
    setRepresentativeModalIsVisible(true);
  }, [setRepresentativeModalIsVisible]);

  const handleCloseRepresentativeModal = useCallback(() => {
    setRepresentativeModalIsVisible(false);
  }, [setRepresentativeModalIsVisible]);

  const [customerModalIsVisible, setCustomerModalIsVisible] = useState<boolean>(false);
  const handleShowCustomerModal = useCallback(() => {
    setCustomerModalIsVisible(true);
  }, [setCustomerModalIsVisible]);

  const handleCloseCustomerModal = useCallback(() => {
    setCustomerModalIsVisible(false);
  }, [setRepresentativeModalIsVisible]);
  if (loadingOrderDetail) return <LoadingComponents />;

  return (
    <>
      <Row justify="start" align="top" gutter={[32, 24]}>
        <Col xs={24} sm={20} md={20} lg={20} xl={20} xxl={20} className="gutter-row">
          <div className="payment-container">
            <Text style={{ fontWeight: 'bold' }}>Lojista</Text>
            <div className="d-flex" style={{ flexDirection: 'column' }}>
              <Text>
                # {order.customerCode} - {order.customerName}
              </Text>
              <Text>
                {order.customerCpfCnpj?.length > 11
                  ? formatCnpj(order.customerCpfCnpj)
                  : formatCpf(order.customerCpfCnpj)}
              </Text>
            </div>
            {hasMerpIntegration ? (
              <Button
                style={{ width: '20em' }}
                title="Trocar lojista"
                icon={<UserSwitchOutlined />}
                type="primary"
                onClick={handleShowCustomerModal}
              >
                Trocar lojista
              </Button>
            ) : (
              <div style={{ width: '20em' }}></div>
            )}
          </div>

          <div className="payment-container">
            <Text style={{ fontWeight: 'bold' }}>Representante</Text>
            <div className="d-flex" style={{ flexDirection: 'column' }}>
              <Text>
                #{order.representativeCode} - {order.representativeName}
              </Text>
              <Text>
                {order.representativeCpfCnpj?.length > 11
                  ? formatCnpj(order.representativeCpfCnpj)
                  : formatCpf(order.representativeCpfCnpj)}
              </Text>
            </div>
            {hasMerpIntegration ? (
              <Button
                style={{ width: '20em' }}
                title="Trocar representante"
                icon={<UserSwitchOutlined />}
                type="primary"
                onClick={handleShowRepresentativeModal}
              >
                Trocar representante
              </Button>
            ) : (
              <div style={{ width: '20em' }}></div>
            )}
          </div>

          <div className="payment-container">
            <Text style={{ fontWeight: 'bold' }}>Endereço</Text>
            <Text>
              {order.shippingAddress?.address}, {order.shippingAddress?.number} - {order.shippingAddress?.cep}
            </Text>
            <div style={{ width: '20em' }}></div>
          </div>
          <div className="payment-container">
            <Text style={{ fontWeight: 'bold' }}>Cidade</Text>
            <Text>
              {order.shippingAddress?.cityName}, {order.shippingAddress?.stateAbbreviation}
            </Text>
            <div style={{ width: '20em' }}></div>
          </div>
        </Col>
      </Row>

      <FullScreenModal
        destroyOnClose={true}
        footer={null}
        forceRender={false}
        open={representativeModalIsVisible}
        onOk={handleCloseRepresentativeModal}
        onCancel={handleCloseRepresentativeModal}
        bodyStyle={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <ChangeRepresentativeForm
          currentRepresentative={order.representativeCode}
          actualOrder={order}
          handleCloseRepresentativeModal={handleCloseRepresentativeModal}
          option={option}
        />
      </FullScreenModal>

      <FullScreenModal
        destroyOnClose={true}
        footer={null}
        forceRender={false}
        open={customerModalIsVisible}
        onOk={handleCloseCustomerModal}
        onCancel={handleCloseCustomerModal}
        bodyStyle={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <ChangeCustomerForm
          currentCustomer={order.customerCode}
          actualOrder={order}
          handleCloseCustomerModal={handleCloseCustomerModal}
          option={option}
        />
      </FullScreenModal>
    </>
  );
};

export default Detail;
