import {
  Alert,
  Progress,
  Space,
  Table,
  Typography,
} from 'antd';

import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import HeaderButton from '@components/HeaderButton';
import { useUploadCustomers } from '@hooks/UploadCustomersContext';
import { CustomerAddress } from '@models/CustomerAddress';
import { formatCnpj } from '@utils/cnpj-mask';

const { Text } = Typography;

const UploadCustomersContent: React.FC = () => {
  const { customers, registering } = useUploadCustomers();

  const columns = [
    {
      dataIndex: 'code',
      title: 'Código',
    },
    { dataIndex: 'name', title: 'Nome do lojista' },
    { dataIndex: 'cnpj', title: 'CNPJ', render: (cnpj: string) => formatCnpj(cnpj) },
    { dataIndex: 'addresses', title: 'Cidade', render: (address: CustomerAddress[]) => address[0].cityName },
    {
      dataIndex: 'code',
      title: 'Status',
      render: (code: number) => {
        if (code)
          return (
            <Space>
              {code > 1 ? (
                <CheckCircleOutlined style={{ color: 'green' }} />
              ) : (
                <CloseCircleOutlined style={{ color: 'red' }} />
              )}
              <Text strong> {code > 1 ? 'Enviado' : 'Falhou'}</Text>
            </Space>
          );
      },
    },
  ];

  const handleDownloadCSV = () => {
    const csvData = `CNPJ,INSCRICAO_ESTADUAL,RAZAO_SOCIAL,NOME,CEP,ESTADO,CIDADE,BAIRRO,ENDERECO,NUMERO,TELEFONE,E_MAIL,CONTATO,CELULAR
45178579000132,1234567890,PADARIA DO JOAO LTDA,Maria Souza,90000-000,RS,PORTO ALEGRE,CENTRO,RUA DAS FLORES,123,51987654321,padariadojoao@email.com,Joao Silva,51991234567
78945612300010,9876543210,MERCEARIA DA MARIA,Jose Santos,92000-000,RS,CANOAS,FLORESTA,AV. PRINCIPAL,456,51976543210,merceariadamaria@email.com,Maria Oliveira,51981234567
10298745600018,8765432190,LOJA DE ROUPAS,Pedro Almeida,93000-000,RS,NOVO HAMBURGO,JARDIM,RUA SECUNDARIA,789,51965432109,lojaderoupas@email.com,Ana Pereira,51971234567`;

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'exemplo.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open('data:text/csv;charset=utf-8,' + encodeURI(csvData));
    }
  };

  if (customers.length === 0)
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {' '}
        {/* Centering div */}
        <HeaderButton
          text="Baixar arquivo de exemplo .csv"
          onClick={handleDownloadCSV}
          Icon={<DownloadOutlined style={{ fontSize: '20px', color: 'var(--primary-color)' }} />}
        />
      </div>
    );

  const registeredCount = customers.filter((e) => e.code).length;
  const progressPercent = (registeredCount / customers.length) * 100;
  const isCompleted = registeredCount === customers.length;

  return (
    <div className="upload-customers-content">
      {registering && (
        <Progress
          percent={progressPercent}
          showInfo={true}
          strokeLinecap="round"
          strokeWidth={12}
          status="active"
          style={{ width: '100%', marginBottom: 16 }}
          format={(percent) => `${percent?.toFixed(0)}%`}
        />
      )}

      {isCompleted && (
        <Alert
          message="Cadastro concluído com sucesso!"
          type="success"
          showIcon
          icon={<CheckCircleOutlined />}
          style={{ marginBottom: 16 }}
        />
      )}

      <Table className="mt-4" dataSource={customers} columns={columns} />
    </div>
  );
};

export default UploadCustomersContent;
