import { AxiosResponse } from 'axios';

import { Order } from '@models/Order';
import { ShoppingBagOrderJson } from '@models/ShoppingBagOrderJson';

import * as api from './api';
import * as apiMerp from './api-merp';

export type ImageByOrderCodeEncriptedResponseType = {
  referenceCode: string;
  colorCode: string;
  urls: string[];
};

export type OrderInfoResponseType = {
  manufacturerName: string;
  customerName: string;
  orderCode: string;
  orderDate: Date;
  references: ImageByOrderCodeEncriptedResponseType[];
};

export function get(params: any): Promise<any> {
  return api.get('orders', params);
}

export function getFailed(params: any): Promise<any> {
  return api.get('orders/failed', params);
}

export function getByCode(code: number): Promise<any> {
  return api.get(`orders/${code}`);
}

export function getFailedDetail(sequence: number): Promise<any> {
  return api.get(`orders/failed/${sequence}`);
}

export function getImagesByOrderCodeEncripted(
  orderCodeEncrypted: string,
  integrationId: number,
  imageSize: 'image' | 'image_medium' | 'image_small' = 'image',
): Promise<AxiosResponse<OrderInfoResponseType>> {
  return api.get(`orders/${orderCodeEncrypted}/image-color-reference/encrypted`, { integrationId, imageSize });
}

export function encryptOrderCode(
  integrationId: number,
  orderCode: number,
): Promise<AxiosResponse<{ orderCode: string }>> {
  return api.get(`orders/encrypt-order-code/${integrationId}/${orderCode}`);
}

export function generateFailedOrderByShoppingBag(bagJson: ShoppingBagOrderJson) {
  return api.post('orders/order-failed/shopping-bag', bagJson);
}

export function updateOrderUsingMerp(order: Order): Promise<any> {
  return apiMerp.patch(`order/${order?.orderCode}`, order);
}

export function generateAndDownlodPDF(order: Order): Promise<any> {
  return apiMerp.getBlob(`order/pdf/${order?.orderCode}`);
}
