import { AxiosResponse } from 'axios';

import * as api from './api';

export function getCustomers(options: any): Promise<AxiosResponse<any>> {
  return api.get('legalperson', options);
}

export function getCustomerByCode(code: number): Promise<any> {
  return api.get(`legalperson/${code}`);
}

export function changeCustomerStatus(code: number, status: string): Promise<any> {
  return api.patch(`legalperson/status`, { status, legalPersonCode: code });
}

export function acceptCustomerInBrand(brands: any[], legalPersonCode: number): Promise<any> {
  return api.patch(`legalperson/brands`, { brands, legalPersonCode });
}

export function createCustomer(body: any): Promise<AxiosResponse<any>> {
  return api.post('v2/legalperson', body);
}
