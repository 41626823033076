import React, { useState } from 'react';

import { Col, Row, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';

import { EditFilled, SearchOutlined } from '@ant-design/icons';
import PriceTableForm from '@components/PriceTableForm';
import TableFilters from '@components/TableFilters';
import { useIntegration } from '@hooks/IntegrationContext';
import { usePriceTable } from '@hooks/PriceTableContext';
import { PriceTable } from '@models/PriceTable';
import { FullScreenModal } from '@styles/globals';

import ContentTable from '../ContentTable';

const { Text } = Typography;

const PriceTablesContent: React.FC = () => {
  const { hasMerpIntegration } = useIntegration();
  const [expandedRowKeys, setExpandedRowKeys] = useState<any[]>([]);

  const {
    loadPriceTables,
    priceTables,
    loadPriceTableDetails,
    priceTableCurrentPage,
    priceTableTotal,
    priceTableFilters,
    loading,
  } = usePriceTable();

  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [editPriceTableModalIsVisible, setEditPriceTableModalIsVisible] = useState(false);
  const [currentPriceTable, setCurrentPriceTable] = useState<PriceTable>({} as PriceTable);

  const onCloseEditPriceTableModal = () => setEditPriceTableModalIsVisible(false);

  const onShowEditPriceTableModal = React.useCallback(
    (priceTable: PriceTable) => {
      setCurrentPriceTable(priceTable);
      setEditPriceTableModalIsVisible(true);
    },
    [setCurrentPriceTable, setEditPriceTableModalIsVisible],
  );

  const dateSorter = (productA: any, productB: any) => {
    const date1 = new Date(productA.startDate).getTime();
    const date2 = new Date(productB.startDate).getTime();
    return date1 - date2;
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Código',
      dataIndex: 'code',
      align: 'center' as 'center',
      render: (value: any) => <Text>#{value}</Text>,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <TableFilters
          inputPlaceholder={'Filtrar pelo Código'}
          inputValue={selectedKeys[0]}
          onChangeInputValue={setSelectedKeys}
          onFilter={confirm}
          onClearFilters={clearFilters}
        />
      ),
      filterIcon: () => <SearchOutlined />,
      onFilter: (value: any, record: any) => {
        return record.code ? record.code.toString().toLowerCase().includes(value.toLowerCase()) : '';
      },
      sorter: true,
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <TableFilters
          inputPlaceholder={'Filtrar pela Descrição'}
          inputValue={selectedKeys[0]}
          onChangeInputValue={setSelectedKeys}
          onFilter={confirm}
          onClearFilters={clearFilters}
        />
      ),
      filterIcon: () => <SearchOutlined />,
      onFilter: (value: any, record: any) => {
        return record.description ? record.description.toString().toLowerCase().includes(value.toLowerCase()) : '';
      },
      sorter: true,
    },
    {
      title: 'Sigla',
      dataIndex: 'codeName',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <TableFilters
          inputPlaceholder={'Filtrar pela Sigla'}
          inputValue={selectedKeys[0]}
          onChangeInputValue={setSelectedKeys}
          onFilter={confirm}
          onClearFilters={clearFilters}
        />
      ),
      filterIcon: () => <SearchOutlined />,
      onFilter: (value: any, record: any) => {
        return record.codeName ? record.codeName.toString().toLowerCase().includes(value.toLowerCase()) : '';
      },
      sorter: true,
    },
    {
      title: 'Data Inicial',
      dataIndex: 'startDate',
      align: 'center' as 'center',
      render: (value: any) => <Text>{value ? moment.utc(value).format('DD/MM/YYYY') : 'Sem data'}</Text>,
      sorter: true,
    },
    {
      title: 'Data Final',
      dataIndex: 'endDate',
      align: 'center' as 'center',
      render: (value: any) => <Text>{value ? moment.utc(value).format('DD/MM/YYYY') : 'Sem data'}</Text>,
      sorter: true,
    },
    {
      title: 'Variação (%)',
      dataIndex: 'variationPercentage',
      align: 'right' as 'right',
      render: (value: any) => (value ? <Text>{value}</Text> : <Text>0</Text>),
      sorter: true,
    },
    {
      title: 'Variação (Valor)',
      dataIndex: 'variationValue',
      align: 'right' as 'right',
      render: (value: any) => (value ? <Text>{value.toFixed(2)}</Text> : <Text>0,00</Text>),
      sorter: true,
    },
    {
      title: 'Ações',
      dataIndex: 'actions',
      align: 'center' as 'center',
      render: (value: any, priceTable: PriceTable): JSX.Element => {
        return (
          <>
            {hasMerpIntegration && (
              <EditFilled
                onClick={() => onShowEditPriceTableModal(priceTable)}
                style={{ fontSize: '24px', color: '#510c76' }}
              />
            )}
          </>
        );
      },
    },
  ];

  const onTableRowExpand = (expanded: any, record: any) => {
    const keys = [];

    if (expanded) {
      keys.push(record.code);
    }

    setExpandedRowKeys(keys);

    if (expanded) return loadPriceTableDetails(Number(record.code));
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.hasOwnProperty('column')) {
      priceTableFilters.field = `${sorter.field}`;
      priceTableFilters.order = `${sorter.order}`;
    }

    loadPriceTables(pagination.current, priceTableFilters);
  };

  return (
    <>
      <FullScreenModal
        destroyOnClose={true}
        open={editPriceTableModalIsVisible}
        onOk={onCloseEditPriceTableModal}
        onCancel={onCloseEditPriceTableModal}
        footer={null}
        forceRender={false}
        maskClosable={false}
        bodyStyle={{ padding: 0, margin: 0, width: '100vw', maxWidth: '100vw' }}
      >
        <div style={{ maxHeight: '92vh', overflowY: 'auto', margin: '0' }}>
          <Row>
            <Col
              span={24}
              className="d-flex"
              style={{ minWidth: '1000px', height: '100%', paddingLeft: 50, paddingRight: 50 }}
            >
              <PriceTableForm
                onCloseModal={onCloseEditPriceTableModal}
                isEditing={true}
                priceTableToEdit={currentPriceTable}
              />
            </Col>
          </Row>
        </div>
      </FullScreenModal>

      <Table
        className="mt-4"
        columns={columns}
        scroll={{ x: 800 }}
        dataSource={priceTables}
        pagination={{ pageSize: 10, current: priceTableCurrentPage, total: priceTableTotal }}
        expandable={{
          expandedRowRender: (record) => <ContentTable priceTable={record} />,
          onExpand: onTableRowExpand,
        }}
        onChange={handleTableChange}
        loading={loading}
        rowKey={(record) => record.code}
        expandedRowKeys={expandedRowKeys}
      />
    </>
  );
};

export default PriceTablesContent;
