import React from 'react';

import UploadCustomersContent from './components/UploadCustomersContent';
import UploadCustomersHeader from './components/UploadCustomersHeader';

interface UploadCustomersProps {}
const UploadCustomers: React.FC<UploadCustomersProps> = () => {
  return (
    <>
      <UploadCustomersHeader />
      <UploadCustomersContent />
    </>
  );
};

export default UploadCustomers;
