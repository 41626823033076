import React, { useEffect } from 'react';

import { Table } from 'antd';

import { useOrder } from '@hooks/OrdersContext';

import { columns } from '../../columns';
import OrderItemsTabs from '../OrderItemsTabs';

const InProgressTab: React.FC = () => {
  const {
    inProgressPage,
    inProgressOrders,
    loadInProgressOrders,
    inProgressTotal,
    loadingInProgressOrders,
    inProgressFilters,
  } = useOrder();

  useEffect(() => {
    loadInProgressOrders(0);
  }, []);

  const handleTableStatus = (pagination: any, filters: any, sorter: any) => {
    if (sorter.hasOwnProperty('column')) {
      inProgressFilters.field = `${sorter.field}`;
      inProgressFilters.order = `${sorter.order}`;
    }

    loadInProgressOrders(pagination.current, inProgressFilters);
  };

  return (
    <Table
      columns={columns}
      dataSource={inProgressOrders}
      onChange={handleTableStatus}
      loading={loadingInProgressOrders}
      pagination={{
        pageSize: 10,
        total: inProgressTotal,
        current: inProgressPage,
        showSizeChanger: false,
        showQuickJumper: false,
      }}
      expandable={{
        expandedRowRender: (record) => <OrderItemsTabs order={record} option="in-progress" />,
      }}
      scroll={{ x: 800 }}
      rowKey={(record) => record.orderCode}
    />
  );
};

export default InProgressTab;
